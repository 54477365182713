import React from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

// page components
import LeftDrawer from './left-drawer';
import AppBar from './app-bar';
import { useTheme } from '@mui/material';

const appBarHeight = 50;

const MainContainer = styled('div')(({ theme, drawerWidth }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  marginTop: `${appBarHeight + 5}px`,
  position: 'relative',
  transition: theme.transitions.create(['margin-left', 'width', 'margin-top'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const MainContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create(['margin-left'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Main = ({ children }) => {
  const theme = useTheme();
  const location = useLocation(); // Hook to get the current route

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <CssBaseline />
      <AppBar height={appBarHeight} />
      <MainContainer>
        <LeftDrawer currentPath={location.pathname} /> {/* Pass current path as prop */}
        <MainContent theme={theme}>
          {children}
        </MainContent>
      </MainContainer>
    </Box>
  );
};

export default Main;