import {  getDoc, setDoc, doc } from 'firebase/firestore';
import { db } from '../config';

export const fetchUserSettings = async (userId) => {
  const settingsDocRef = doc(db, `users/${userId}/admin/settings`);

  const settingsSnapshot = await getDoc(settingsDocRef);
  
  if (settingsSnapshot.exists()) {
      console.log("User settings fetched successfully"); 
      return settingsSnapshot.data();
  } else {
      console.log("No settings document found"); 
      return null
  }
};


export const updateUserSettings = async (userId, settings) => {
  const settingsDocRef = doc(db, `users/${userId}/admin/settings`);
  try {
    await setDoc(settingsDocRef, settings, { merge: true })
    console.log("User settings updated");
  } 
  catch (error) {console.error("Error updating user settings:", error)}
};

