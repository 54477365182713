// src/components/photo-gallery.jsx

import React from 'react';
import { Box } from '@mui/material';
import PhotoCard from './photo-card';

const PhotoGallery = ({ photoUrls, handleDeleteImage }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: 2,
            }}
        >
            {photoUrls.map((url, index) => (
                <Box
                    key={index}
                    sx={{
                        flex: '1 1 200px',
                        maxWidth: '250px',
                    }}
                >
                    <PhotoCard photoUrl={url} onDelete={() => handleDeleteImage(url)} />
                </Box>
            ))}
        </Box>
    );
};

export default PhotoGallery;