import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore, serverTimestamp } from "firebase/firestore"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAQxEHn88bSnkC7_DZikisDmyk7BdsU-uk",
  authDomain: "stuffi-153b5.firebaseapp.com",
  projectId: "stuffi-153b5",
  storageBucket: "stuffi-153b5.appspot.com",
  messagingSenderId: "997611588936",
  appId: "1:997611588936:web:b2fd3adcef600e661af77d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const storage = getStorage(app);
const auth = getAuth(app)
const timestamp = serverTimestamp;


export { db, storage, auth, timestamp };





