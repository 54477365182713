import { collection, query, where, doc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"; 
import { auth, db } from "../config";

export const fetchOrCreateRootNode = async () => {
  console.log('user', auth.currentUser);
  if (!auth.currentUser) {
   throw new Error('User not authenticated');
 }
 
//  const userId = auth.currentUser.uid;
 
//  // Reference the nodes collection for the current user
//  const nodesRef = collection(db, `users/${userId}/nodes`);
 
//  // Create a query to fetch all nodes for the user
//  const q = query(nodesRef);

//  // Execute the query and get a snapshot of the results
//  const querySnapshot = await getDocs(q);

//  // Initialize an empty array to store the nodes
//  const nodes = [];

//  // Loop through each document in the snapshot and add it to the nodes array
//  querySnapshot.forEach((doc) => {
//    nodes.push({ id: doc.id, ...doc.data() });
//  });
//  // console.log('nodes', nodes);
//  // If no nodes are found, create a root node
//  if (nodes.length === 0) {
   
//    const rootNode = {
//      name: "root",
//      description: "Root node",
//      parentId: null,
//      path: "root",
//      tags: []
//    };
//    const rootNodeRef = doc(nodesRef, "root");
//    await setDoc(rootNodeRef, rootNode);

//    // Add the root node to the nodes array
//    nodes.push({ id: "root", ...rootNode });
//  }

//  // Return the array of nodes
//  return nodes;
};



export const addNode = async (parentNode) => {
  if (!auth.currentUser) {
    throw new Error('User not authenticated');
  }

  const userId = auth.currentUser.uid;
  const nodesRef = collection(db, `users/${userId}/nodes`);
  
  const newNode = {
    name: "",
    description: "",
    parentId: parentNode.id,
    path: `${parentNode.path}`,
    tags: []
  };

  const newNodeRef = await addDoc(nodesRef, newNode);
  return { id: newNodeRef.id, ...newNode };
};


// Fetches all user nodes

export const fetchUserNodes = async () => {
  // Get the current user's ID
  const userId = auth.currentUser.uid;

  // Reference the nodes collection for the current user
  const nodesRef = collection(db, `users/${userId}/nodes`);

  // Create a query to fetch all nodes for the user
  const q = query(nodesRef);

  // Execute the query and get a snapshot of the results
  const querySnapshot = await getDocs(q);

  // Initialize an empty array to store the nodes
  const nodes = [];

  // Loop through each document in the snapshot and add it to the nodes array
  querySnapshot.forEach((doc) => {
    nodes.push({ id: doc.id, ...doc.data() });
  });

  // Return the array of user nodes
  return nodes;
};


export const fetchChildNodes = async (nodeId) => {
 // Get the current user's ID.
 const userId = auth.currentUser.uid;

 // Reference the nodes collection for the current user.
 const nodesRef = collection(db, `users/${userId}/nodes`);

 // Create a query to find documents where the parentId field equals the given nodeId.
 const q = query(nodesRef, where("parentId", "==", nodeId));
 
 // Execute the query and get a snapshot of the results.
 const querySnapshot = await getDocs(q);
 
 // Initialize an empty array to store the child nodes.
 const nodes = [];
 
 // Loop through each document in the snapshot and add it to the nodes array.
 querySnapshot.forEach((doc) => {
   // Push the document's ID and its data to the nodes array.
   nodes.push({ id: doc.id, ...doc.data() });
 });
 
 // Return the array of child nodes.
 return nodes;
};

// Fetch items by node
export const getItemsByNode = async (nodeId) => {
    const userId = auth.currentUser.uid;
    const childNodes = await fetchChildNodes(userId, nodeId);
    childNodes[nodeId] = true; // Include the node itself
  
    const itemsRef = collection(db, `users/${userId}/items`);
    const itemQueries = Object.keys(childNodes).map(childNodeId => where(`nodes.${childNodeId}`, "==", true));
    
    const q = query(itemsRef, ...itemQueries);
    const querySnapshot = await getDocs(q);
    
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
  
    return items;
  };

// Fetch items by keyword
export const getItemsByKeyword = async (keyword) => {
  const userId = auth.currentUser.uid;
  const itemsRef = collection(db, `users/${userId}/items`);
  const q = query(itemsRef, where("keywords", "array-contains-any", [keyword]));
  const querySnapshot = await getDocs(q);
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push(doc.data());
  });
  return items;
}


/**
 * Fetches all ancestor nodes of a given node.
 * @param {string} nodeId - The ID of the node whose ancestors are to be fetched.
 * @returns {Promise<Array>} - A promise that resolves to an array of ancestor nodes.
 * @throws {Error} - Throws an error if the node is not found.
 */
export const getAncestorNodes = async (nodeId) => {
  // Get the current user's ID.
  const userId = auth.currentUser.uid;

  // Reference the specific node document for the given nodeId.
  const nodeRef = doc(db, `users/${userId}/nodes`, nodeId);
  
  // Fetch the node document.
  const nodeDoc = await getDoc(nodeRef);

  // If the node document exists, proceed to fetch its ancestors.
  if (nodeDoc.exists()) {
    // Extract the path field from the node document.
    const { path } = nodeDoc.data();
    
    // Split the path into segments for each ancestor.
    const pathSegments = path.split('/');

    // Reference the nodes collection for the current user.
    const nodesRef = collection(db, `users/${userId}/nodes`);
    
    // Create a query to find documents where the path field matches any segment of the node's path.
    const q = query(nodesRef, where("path", "in", pathSegments));
    
    // Execute the query and get a snapshot of the results.
    const querySnapshot = await getDocs(q);
    
    // Initialize an empty array to store the ancestor nodes.
    const ancestors = [];
    
    // Loop through each document in the snapshot and add it to the ancestors array.
    querySnapshot.forEach((doc) => {
      // Push the document's ID and its data to the ancestors array.
      ancestors.push({ id: doc.id, ...doc.data() });
    });
    
    // Return the array of ancestor nodes.
    return ancestors;
  } else {
    // If the node document does not exist, throw an error.
    throw new Error("Node not found");
  }
};