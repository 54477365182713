

import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Container, IconButton, AppBar, Toolbar } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBasic from './basic-settings';
import { fetchUserSettings, updateUserSettings } from '../../firebase/query/settings';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Settings = () => {
    const [value, setValue] = useState(0);
    const [settings, setSettings] = useState(() => (
        JSON.parse(sessionStorage.getItem('settings')) || {
            autosave: false,
            localCaching: false
        }));

    useEffect(() => {
        const fetchSettings = async () => {
            const userId = sessionStorage.getItem('userId');
            if (userId) {
                const fetchedSettings = await fetchUserSettings(userId);
                if (fetchedSettings) {
                    setSettings(fetchedSettings);
                    sessionStorage.setItem('settings', JSON.stringify(fetchedSettings));
                }
            }
        };

        fetchSettings();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const settingsHandler = (key) => (event) => {
        setSettings(prevSettings => ({ ...prevSettings, [key]: event.target.checked }));
    };

    const saveUserSettings = async () => {
        const userId = sessionStorage.getItem('userId');
        const newSettings = settings;

        if (!userId) {
            console.error('User not authenticated');
            return;
        }

        if (sessionStorage.getItem('settings')) {
            // Case 1: Update existing settings
            sessionStorage.setItem('settings', JSON.stringify(newSettings));
            await updateUserSettings(userId, newSettings);
        } else {
            // Case 2: No settings exist in session storage
            sessionStorage.setItem('settings', JSON.stringify(newSettings));

            const existingSettings = await fetchUserSettings(userId);
            if (existingSettings) {
                await updateUserSettings(userId, newSettings);
            } else {
                await updateUserSettings(userId, newSettings); // Creates the document if it doesn't exist
            }
        }

        console.log('Settings changed to:', settings);
    };

    return (
        <Container disableGutters component="div">

            {/* <CssBaseline /> */}
                <AppBar position="static" color="default" elevation={0}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="save settings"
                            onClick={saveUserSettings}
                            // sx={{ marginRight: 2 }}
                        >
                            <SaveIcon />
                        </IconButton>
                        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                            <Tab label="Basic" {...a11yProps(0)} />
                            <Tab label="Advanced" {...a11yProps(1)} />
                        </Tabs>
                    </Toolbar>
                </AppBar>
                <TabPanel component={'div'} value={value} index={0}>
                    <SettingsBasic
                        settings={settings}
                        settingsHandler={settingsHandler}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {/* Future Advanced Settings Component */}
                </TabPanel>

        </Container>
    );
};

export default Settings;