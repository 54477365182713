// src/components/photo-card.jsx

import React from 'react';
import { Card, CardMedia, CardActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const PhotoCard = ({ photoUrl, onDelete }) => {
  
  return (
    <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        height="194"
        image={photoUrl}
        alt="Item Image"
        sx={{ flexGrow: 1 }}
      />
      <CardActions disableSpacing>
        <IconButton
          aria-label="delete"
          onClick={() => onDelete(photoUrl)}
          style={{ marginLeft: 'auto' }} // Aligns the delete button to the right
        >
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default PhotoCard;