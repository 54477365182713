// src/routes/item.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// mui
import { Typography, Box, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PhotoGallery from '../../components/photo-gallery';
import { DeleteButton } from '../../components/buttons';
import { getItem, deleteItem } from '../../firebase/query/item-query';
import { useItemHandlers } from './item-handlers';
import { auth } from '../../firebase/config';
import { deleteImagesStorage } from '../../firebase/query/image-query';
import { toast } from 'react-toastify';

const Item = () => {
  const { itemId } = useParams();
  const location = useLocation()
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  // const [editMode, setEditMode] = useState(useState(location.state && location.state.fromNew ? true : false));
  const [editMode, setEditMode] = useState(location.state && location.state.fromNew ? true : false);
  const [editValues, setEditValues] = useState({
    name: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const handler = useItemHandlers({ itemId, setItem, setEditMode, setEditValues, sessionStorage, setLoading });
  const { handleUpdate, 
    handleDelete, 
    handleDeleteImage, 
    handleCopy, 
    handleAddPhotos,
    handleAddPhotosWithProgress
  } = handler


  useEffect(() => {
    const fetchItem = async () => {
      try {
        const itemData = await getItem(itemId);
        setItem(itemData);
        setEditValues({ name: itemData.name, description: itemData.description });
      } catch (error) {
        console.error(error);
        // Handle error, maybe redirect or show a message
      }
    };
    fetchItem();
  }, [itemId]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleCancel = async () => {
    
    console.log((editValues.name === '' && editValues.description === '') );
    if (editValues.name === '') {
      try {
        const userId = auth.currentUser.uid
        await deleteItem(itemId);
        await deleteImagesStorage(userId, itemId)
        navigate('/items/grid'); // Redirect to a list or any other appropriate page
      } catch (error) {
        console.error("Error deleting item: ", error);
        // Handle delete error, maybe show a message
      }
    } else {
      setEditMode(false);
    }
  };

  if (!item) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '1400px', padding: '16px' }}>
      <Box sx={{ marginBottom: 2 }}>
        {editMode ? (
          <>
            <TextField
              name="name"
              label="Name"
              value={editValues.name}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="description"
              label="Description"
              value={editValues.description}
              onChange={handleEditChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
          </>
        ) : (
          <>
            <Typography variant="h4">{item.name}</Typography>
            <Typography variant="body1">{item.description}</Typography>
          </>
        )}

        <input
          accept="image/*"
          id="upload-photo"
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleAddPhotosWithProgress}
        />
        <label htmlFor="upload-photo">
          <IconButton color="primary" component="span" disabled={loading}>
            <AddAPhotoIcon />
          </IconButton>
        </label>
        <IconButton onClick={editMode ? handleCancel : () => setEditMode(!editMode)}>
          {editMode ? <CancelIcon/> : <EditIcon />}
        </IconButton>

        {editMode && 
        <IconButton 
        onClick={() => handleUpdate(itemId, editValues)}
        disabled={editValues.name.trim() === ''}
        ><SaveIcon /></IconButton>
        }

        <IconButton onClick={handleCopy}><FileCopyIcon /></IconButton>



        {!editMode && DeleteButton(handleDelete)}
      </Box>
      {item.photoUrls && item.photoUrls.length > 0 && (
        <PhotoGallery photoUrls={item.photoUrls} handleDeleteImage={handleDeleteImage} />
      )}
    </Box>
  );
};

export default Item;