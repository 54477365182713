import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { addItem } from '../../firebase/query/item-query';

const ItemNew = () => {
  const navigate = useNavigate();
  const effectCalled = useRef(false); // Track if effect is called

  useEffect(() => {
    
    const createNewItem = async () => {
      const newItem = {
        name: '',
        description: '',
        files: []
      };
      
      try {
        // Ensure addItem is not invoked more than once
        if (!effectCalled.current) {
          effectCalled.current = true;
          const newItemRef = await addItem(newItem.name, newItem.description, newItem.files);
          navigate(`/items/${newItemRef.id}`, { state: { fromNew: true } });
        }
      } catch (e) {
        console.error("Error creating new item: ", e);
        // Handle error (e.g., show error message)
      }
    };

    createNewItem();
  }, []);

  return null; // No need to render anything since we're immediately redirecting
};

export default ItemNew;