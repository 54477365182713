import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountMenu from './account-menu'; // Ensure this file exists as per previous iterations
import DrawerContext from '../../contexts/drawer-context';
import MuiAppBar from '@mui/material/AppBar';

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}));


const AppBar = ({height}) => {
    const { toggleDrawerOpen } = useContext(DrawerContext);
    
    return (
            <StyledAppBar
                position="fixed"
                color='appbar'
                sx={{ height: height, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawerOpen}
                        sx={{ position: 'absolute', left: 8 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{ ml: 4, flexGrow: 1 }}
                    >
                        Stuffi
                    </Typography>
                    <AccountMenu sx={{ paddingRight: '5px' }} />
            </StyledAppBar>
    );
};

export default AppBar;