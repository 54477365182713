import React, { useState } from 'react';
import { Card, CardMedia, CardContent, CardActionArea, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ItemCard = ({ item }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hover, setHover] = useState(false);
  const hasMultipleImages = item.photoUrls && item.photoUrls.length > 1;

  const handleClick = () => {
    navigate(`/items/${item.id}`);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % item.photoUrls.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + item.photoUrls.length) % item.photoUrls.length);
  };

  return (
    <Card style={{ width: "240px" }}>
      {/* <CardActionArea
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      > */}
        {item.photoUrls && item.photoUrls.length > 0 && (
          <div 
          onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          style={{ position: 'relative', height: '240px', width: '240px' }}>
            <CardMedia
            
              component="img"
              style={{
                height: "240px", // Set height equal to the width
                width: "240px",  // Ensure CardMedia dimensions
                objectFit: "contain", // Ensure image is resized correctly within the specified dimensions
              }}
              image={item.photoUrls[currentIndex]}
              alt="Item Image"
            />
            {hasMultipleImages && hover && (
              <>
                <IconButton
                  onClick={prevImage}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  onClick={nextImage}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </>
            )}
          </div>
        )}
        {/* </CardActionArea> */}
        <CardActionArea
        onClick={handleClick}>
        <CardContent>
          <Typography variant="h6" component="div">{item.name}</Typography>
          <Typography variant="body2" color="text.secondary">{item.description}</Typography>
        </CardContent>
        </CardActionArea>
      
    </Card>
  );
};

export default ItemCard;