import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const DrawerContext = createContext();

const drawerWidths = {
  full: '125px', // width when the drawer is open
  icon: '45px', // width when the drawer is in icon mode
  closed: 0 // width when the drawer is hidden
};

export const DrawerProvider = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerState, setDrawerState] = useState(isSmallScreen? 'icon': 'full');
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawerIsIcon, setDrawerIsIcon] = useState(isSmallScreen);
  const [drawerWidth, setDrawerWidth] = useState(drawerWidths[drawerState])
  
  const toggleDrawerIsIcon = () => {setDrawerIsIcon(!drawerIsIcon)}
  const toggleDrawerOpen = () => {setDrawerOpen(!drawerOpen)}
  
  useEffect(() => {
    if (!drawerOpen) {setDrawerState('closed')}
    else if ((isSmallScreen || drawerIsIcon) && drawerOpen) {setDrawerState('icon')}
    else if (drawerOpen) {setDrawerState('full')}
    setDrawerWidth(drawerWidths[drawerState])
  }, [isSmallScreen, drawerState, drawerIsIcon, drawerOpen]);

  return (
    <DrawerContext.Provider value={{
      drawerState,
      toggleDrawerOpen,
      toggleDrawerIsIcon,
      drawerWidth
  
    }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerContext;