import React from 'react';
import { FormGroup, FormControlLabel, Switch, Typography } from '@mui/material';

const SettingsBasic = ({ settings, settingsHandler }) => {
    
    
    return (
        
        <FormGroup >
           
            <Typography variant="h6">Basic Settings</Typography> 
            {/* <Typography component="p" >Basic Settings</Typography>  */}
            
            <FormControlLabel
                control={<Switch checked={settings.autosave} onChange={settingsHandler('autosave')} />}
                label="Autosave"
            />
        </FormGroup>
    );
};

export default SettingsBasic;