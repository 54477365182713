import { useNavigate, useLocation } from 'react-router-dom';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { auth, db } from '../firebase/config';
import { Copyright } from '../components/copyright';
import { fetchUserSettings, updateUserSettings } from '../firebase/query/settings';


export default function Entry() {


  let location = useLocation()
  const navigate = useNavigate()
  let pageTitle = location.pathname === '/signup' ? 'Sign up' : 'Sign in'
  
  const entryErrorHandler = (errCode) => {
    if (errCode === 'auth/invalid-email') { console.log('Invalid email') }
    if (errCode === 'auth/email-already-in-use') { console.log('Email already in use') }
    if (errCode === 'auth/invalid-credential') { console.log('Invalid credentials') }
    if (errCode === 'auth/missing-password') { console.log('Enter password') }
    if (errCode === 'auth/missing-email') { console.log('Enter email') }
    if (errCode === 'auth/missing-auth/too-many-requests') { console.log('Enter password') }
  
  }
  

  const handleEntry = async (event) => {
    let redirectTo = '/items';
    
    event.preventDefault();
  
    try {
      const data = new FormData(event.currentTarget);
      const email = data.get('email');
      const password = data.get('password');
      let resp;
      let firstName;
      let lastName;
      
      // Sign up
      if (location.pathname === '/signup') {
        resp = await createUserWithEmailAndPassword(auth, email, password);
        firstName = data.get('firstName');
        lastName = data.get('lastName');
        
        // Create user document in Firestore if Sign up
        await setDoc(doc(db, 'users', resp.user.uid), {
          email: email,
          name: `${firstName} ${lastName}`,
          firstName: firstName,
          lastName: lastName,
          avatar: email.charAt(0).toUpperCase(),
          memberSince: serverTimestamp()
        });
      } else { 
        // Sign in
        resp = await signInWithEmailAndPassword(auth, email, password);
        
      }
  
      // Fetch or create user settings
      const settingsDoc = await fetchUserSettings(resp.user.uid);
      
      if (!settingsDoc) {
        await updateUserSettings(resp.user.uid, { /* default settings here */ });
      }
  
      const authToken = resp._tokenResponse.refreshToken;
      const userId = resp.user.uid;
      const avatar = email.charAt(0).toUpperCase();
  
      // Initialize sessionStorage
      sessionStorage.setItem('authToken', authToken);
      sessionStorage.setItem('userId', userId);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('avatar', avatar);
  
      navigate(redirectTo);
  
    } catch (error) {
      entryErrorHandler(error.code);
    }
  };


  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          // marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          {/* <LockOutlinedIcon /> */}
        </Avatar>
        <Typography component="h1" variant="h5">
          {pageTitle}
        </Typography>
        <Box component="form" noValidate onSubmit={(e) => handleEntry(e)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>

            {location.pathname === '/signup' &&
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />

                </Grid>
              </>
            }
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            {location.pathname === '/signup' &&
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="Please add me to the Red Baron Pizza of the Month Club."
                />
              </Grid>}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {pageTitle}
          </Button>
          {
          location.pathname === '/signup' &&
            <Grid container justifyContent="flex-end">
              <Grid item>
                Already have an account?&nbsp;

                <NavLink 
                to='/signin' 
                variant="body2"
                style={{ textDecoration: 'none' }}
                >
                  Sign in
                </NavLink>
              </Grid>
            </Grid>
            }
            {
          location.pathname === '/signin' &&
             <Grid container>
              <Grid item xs>
                <NavLink 
                to="/signin" 
                variant="body2"
                style={{ textDecoration: 'none' }}
                >
                  Forgot password?
                </NavLink>
              </Grid>
              <Grid item>
                {"Don't have an account? "}
                <NavLink to={'/signup'} 
                variant="body2"
                style={{ textDecoration: 'none' }}
                >
                  {'Sign up'}
                </NavLink>
              </Grid>
            </Grid>
            }
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>


  );
}