import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

export function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <NavLink color="inherit" to="/">
          stuffi
        </NavLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }