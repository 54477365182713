import React, { useState, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Box, Button } from '@mui/material';

import { updateItem } from '../../firebase/query/item-query';


const ItemsTable = ({ items }) => {
  const [rowData, setRowData] = useState(items);
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    setRowData(items);
  }, [items]);


  const columnDefs = [
    {
      headerName: 'ID',
      field: 'id',
      editable: false,
      cellRenderer: (params) => (
        <NavLink to={`/items/${params.value}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {params.value}
        </NavLink>
      )

    },
    { headerName: 'Name', field: 'name', editable: true },
    { headerName: 'Description', field: 'description', editable: true },
  ];


  const onCellEditRequest = useCallback((event) => {
    const { data, colDef, newValue } = event;
    const updatedRowData = rowData.map(row => {
      if (row.id === data.id) {
        return { ...row, [colDef.field]: newValue };
      }
      return row;
    });
    setRowData(updatedRowData);

    // Collect changes for bulk update
    setUpdates(prev => ({
      ...prev,
      [data.id]: { ...prev[data.id], [colDef.field]: newValue }
    }));
  }, [rowData]);

  const handleSave = async () => {
    try {
      for (const [id, updateData] of Object.entries(updates)) {
        await updateItem(id, updateData);
      }
      setUpdates({});
      console.log("All changes saved!");
    } catch (error) {
      console.error("Error saving updates: ", error);
    }
  };


  return (
    <Box >
      <Button variant="contained" color="primary" onClick={handleSave} style={{ marginBottom: '10px' }}>
        Save
      </Button>
      <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>

        <AgGridReact
          rowData={items}
          columnDefs={columnDefs}
          // editType="fullRow"
          onCellEditRequest={onCellEditRequest}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
    </Box>
  );
};

export default ItemsTable;