import imageCompression from 'browser-image-compression';

// Helper functions
export const validateImageFiles = (files) => {
  const validExtensions = ['.jpg', '.png'];
  return Array.from(files).every(file =>
    validExtensions.some(ext => file.name.toLowerCase().endsWith(ext))
  );
};

export const compressImage = async (imageFile, maxSizeMB) => {
    const options = {
      maxSizeMB,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
  
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  };

export const createThumbnail = async (file, maxSize) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    return new Promise((resolve) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scale = maxSize / Math.max(img.width, img.height);
        const width = img.width * scale;
        const height = img.height * scale;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          const thumb = new File([blob], file.name.replace(/(\.\w+)$/, '_thumb$1'), { type: file.type });
          resolve(thumb);
        }, file.type);
      };
    });
  };
  

export const formatImageFileName = (file) => {
    const datePrefix = new Date().toISOString().slice(2, 10).replace(/-/g, '-');
    const fileNameParts = file.name.split('.');
    const extension = fileNameParts.pop();
    const baseName = fileNameParts.join('.');
    return `${datePrefix}_${baseName}.${extension}`;
  };