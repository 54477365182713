// src/routes/home.js
import { Typography } from '@mui/material';

const Home = () => {

  return (
    <Typography component="div" variant="h6" noWrap>
      Home
    </Typography>
  );
};

export default Home;