// src/routes/items.jsx
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ItemsGrid from './items-grid';
import ItemsTable from './items-table';
import { useLocation } from 'react-router-dom';

import { getItems } from '../../firebase/query/item-query';


const Items = () => {
  const location = useLocation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    
    const fetchItems = async () => {
      try {
        const data = await getItems();
        setItems(data);
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    };

    fetchItems();
  }, []);

  const isGridView = location.pathname.endsWith('grid');

  return (
    <Box>
      {isGridView ? (
        <ItemsGrid items={items} />) : (<ItemsTable items={items} />)}
    </Box>
  );
};

export default Items;