// src/firebase/queries.jsx
import { collection, addDoc, getDoc, getDocs, updateDoc, deleteDoc, doc, arrayUnion } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../config';
import { deleteImages } from './image-query';


export const getUserId = () => sessionStorage.getItem('userId');


export const addItem = async (name, description, files) => {
  
  const userId = getUserId();
  // const userId = auth.currentUser.uid
  
  if (!userId) throw new Error("User not authenticated");
  const itemCollection = collection(db, `users/${userId}/items`);
  const docRef = await addDoc(itemCollection, {
    name,
    description,
    createdAt: new Date(),
  });

  const photoUrls = [];

  if (files && files.length > 0) {
    for (const file of files) {
      const storageRef = ref(storage, `users/${userId}/items/${docRef.id}/${file.name}`);
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);
      photoUrls.push(photoURL);
    }

    await updateDoc(docRef, { photoUrls });
  }

  return docRef;
};

export const getItem = async (itemId) => {
  const userId = getUserId();
  // const userId = auth.currentUser.uid
  if (!userId) throw new Error("User not authenticated");

  const itemDocRef = doc(db, `users/${userId}/items`, itemId);
  const itemSnapshot = await getDoc(itemDocRef);

  if (itemSnapshot.exists()) {
    return itemSnapshot.data();
  } else {
    throw new Error("Item not found");
  }
};

export const getItems = async () => {
  // console.log('user', auth.currentUser.uid);
  const userId = getUserId();
  // const userId = auth.currentUser.uid
  if (!userId) throw new Error("User not authenticated");

  const itemsCollection = collection(db, `users/${userId}/items`);
  const itemsSnapshot = await getDocs(itemsCollection);

  const items = [];
  itemsSnapshot.forEach((itemDoc) => {
    items.push({ id: itemDoc.id, ...itemDoc.data() });
  });
  return items;
};

export const updateItem = async (itemId, newData) => {
  const userId = getUserId();
  // const userId = auth.currentUser.uid
  
  if (!userId) throw new Error("User not authenticated");

  console.log('updating item');
  const itemDocRef = doc(db, `users/${userId}/items`, itemId);
  await updateDoc(itemDocRef, newData);
};

export const deleteItem = async (itemId) => {
  const userId = getUserId();
  // const userId = auth.currentUser.uid
  if (!userId) throw new Error("User not authenticated");

  const itemDocRef = doc(db, `users/${userId}/items`, itemId);
  await deleteDoc(itemDocRef);
  await deleteImages(itemId, userId)
};


export const copyItem = async (userId, itemId) => {
  const itemDocRef = doc(db, `users/${userId}/items`, itemId);
  const itemSnapshot = await getDoc(itemDocRef);

  if (itemSnapshot.exists()) {
    const itemData = itemSnapshot.data();
    const newItemData = {
      ...itemData,
      photoUrls: [],
      createdAt: new Date(),
    };
    const newDocRef = await addDoc(collection(db, `users/${userId}/items`), newItemData);
    return newDocRef.id;
  } else {
    throw new Error("Item not found");
  }
};
