import { getDoc, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { db, storage } from '../config';

const parseFileName = (imageUrl) => {
  const start = imageUrl.indexOf('Fimages%2F') + 'Fimages%2F'.length;
  const end = imageUrl.indexOf('?', start);
  return imageUrl.substring(start, end);
};


// add
export const addImages = async (userId, itemId, files) => {

  // add to storage
  const uploadPromises = files.map(async (file, index) => {
    const fileRef = ref(storage, `users/${userId}/items/${itemId}/images/${Date.now()}_${index}.jpg`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  });

  const downloadURLs = await Promise.all(uploadPromises);

  // add to db
  const itemDocRef = doc(db, 'users', userId, 'items', itemId);
  await updateDoc(itemDocRef, {
    photoUrls: arrayUnion(...downloadURLs)
  });
};

export const addImagesWithProgress = async (userId, itemId, files, onProgress) => {
  // Create a list of promises, each handling an upload for one file
  const uploadPromises = files.map((file, index) => new Promise((resolve, reject) => {
    // Reference to Firebase Storage with a unique file path
    const fileRef = ref(storage, `users/${userId}/items/${itemId}/images/${Date.now()}_${index}.jpg`);
    const uploadTask = uploadBytesResumable(fileRef, file);  // Handle file upload with resumable upload

    // Track the upload progress
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;  // Calculate progress as a percentage
        if (onProgress) {
          onProgress(progress, file);  // Call the progress callback
        }
      },
      (error) => {
        reject(error);  // Reject the promise if there is an error
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);  // Get the file's download URL after upload completes
        resolve(downloadUrl);  // Resolve the promise with the download URL
      }
    );
  }));

  // Wait for all upload promises to complete and collect the download URLs
  const downloadURLs = await Promise.all(uploadPromises);

  // Reference to the Firestore document for the specific user and item
  const itemDocRef = doc(db, 'users', userId, 'items', itemId);

  // Update the Firestore document to include the new photo URLs
  await updateDoc(itemDocRef, {
    photoUrls: arrayUnion(...downloadURLs)  // Add the new URLs to the existing array of photo URLs
  });

  return downloadURLs;  // Return the array of new download URLs
};



//  delete all images for an item in db
export const deleteImagesDb = async (userId, itemId) => {
  const folderRef = ref(storage, `users/${userId}/items/${itemId}`);
  const { items } = await listAll(folderRef);
  const deletePromises = items.map((fileRef) => deleteObject(fileRef));
  await Promise.all(deletePromises);
};

// delete all images for item in storage
export const deleteImagesStorage = async (userId, itemId) => {
  console.log('delete all images', userId, itemId);
  try {
    const folderRef = ref(storage, `users/${userId}/items/${itemId}/images`);
    const { items } = await listAll(folderRef);

    // Map the items to deleteObject promises and await them collectively
    const deletePromises = items.map((fileRef) => deleteObject(fileRef));
    await Promise.all(deletePromises);

    console.log(`All images for item ${itemId} have been deleted.`);
  } catch (error) {
    console.error(`Error deleting images for item ${itemId}:`, error);
    throw error;
  }
};

// delete all images for an item in db and storage
export const deleteImages = (userId, itemId) => {
  console.log('here');
  deleteImagesDb(userId, itemId)
  deleteImagesStorage(userId, itemId)
};

// delete single image from item in db 
export const deleteImageDb = async (userId, itemId, imageUrl) => {
  const itemDocRef = doc(db, `users/${userId}/items`, itemId);
  const itemSnapshot = await getDoc(itemDocRef);

  if (itemSnapshot.exists()) {
    const itemData = itemSnapshot.data();
    const photoUrls = itemData.photoUrls || [];
    const updatedPhotoUrls = photoUrls.filter(url => url !== imageUrl);

    await updateDoc(itemDocRef, { photoUrls: updatedPhotoUrls });
  }
};

// export const deleteImageStorage = async (imageUrl) => {
//     const imageRef = ref(storage, imageUrl);
//     await deleteObject(imageRef);
// };

// delete a single image in storage
export const deleteImageStorage = async (userId, itemId, imageUrl) => {
  console.log('delete all images');
  const imageFileName = parseFileName(imageUrl)
  console.log(parseFileName(imageUrl));

  const imageRef = ref(storage, `users/${userId}/items/${itemId}/images/${imageFileName}`);
  deleteObject(imageRef)
    .then(() => { console.log(`image ${imageFileName} has been deleted.`) })
    .catch((error) => { console.error(`Error deleting image: ${imageFileName}:`, error) });
};

// delete single image from item in db and storage
export const deleteImage = async (userId, itemId, imageUrl) => {
  deleteImageDb(userId, itemId, imageUrl)
  deleteImageStorage(userId, itemId, imageUrl)
};





