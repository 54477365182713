import { useTheme, useMediaQuery } from '@mui/material';
import { useState, useEffect, useCallback, Fragment } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Login from '@mui/icons-material/Login';
import { useNavigate, NavLink } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


export default function AccountMenu() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const avatar = sessionStorage.getItem('avatar')
  const userId = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const handleClick = (event) => { setAnchorEl(event.currentTarget) };
  const handleClose = () => { setAnchorEl(null) };
  const handleLogout = () => { 
    sessionStorage.clear()
    navigate('/signin')
  }
  
  
  return (
    <Fragment>
      <Box >
        <Tooltip title={"Account settings"}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 26, height: 26 }}>{avatar}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />

        {!userId &&
          <MenuItem>
            <ListItemIcon> 
              <PersonAdd fontSize="small" />
            </ListItemIcon>

            <NavLink to="/signup"
            style={{ textDecoration: 'none' }}
            >Sign up</NavLink>
          </MenuItem>}
        <MenuItem onClick={handleClose}>
          
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <NavLink 
            to="/settings"
            style={{ textDecoration: 'none' }}
            >Settings</NavLink>
          
        </MenuItem>
        {userId &&
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <AdminPanelSettingsIcon fontSize="small" />
            </ListItemIcon>
            <NavLink to="/node-admin" style={{ textDecoration: 'none' }}>
              Node Admin
            </NavLink>
          </MenuItem>
        }

        {userId  &&
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>}
        {!userId  &&
          <MenuItem >
            <ListItemIcon>
              <Login fontSize="small" />
            </ListItemIcon>
            <NavLink 
            to="/signin"
            style={{ textDecoration: 'none' }}
            >Sign in</NavLink>
            
          </MenuItem>}

      </Menu>
    </Fragment>
  );
}