// src/pages/items/itemHandlers.js

import { getItem, updateItem, deleteItem } from '../../firebase/query/item-query';
import { deleteImage, deleteImages, addImages, addImagesWithProgress } from '../../firebase/query/image-query';
import { compressImage } from '../../utils/image-utils';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/config';
import { toast } from 'react-toastify';

export const useItemHandlers = ({ itemId, setItem, setEditMode, setEditValues, sessionStorage, setLoading }) => {
  const navigate = useNavigate();
  const userId = sessionStorage.getItem('userId');
  // const userId = auth.currentUser.uid
  const handleUpdate = async (itemId, editValues) => {
    console.log('updates', editValues);
    try {
      await updateItem(itemId, editValues);
      setItem((prevItem) => ({ ...prevItem, ...editValues }));
      setEditMode(false);
    } catch (error) {
      console.error('Error updating item: ', error);
    }
  };


  const handleDelete = async () => {
    try {
      await deleteImages(userId, itemId);  //
      await deleteItem(itemId);
      navigate('/items/grid'); // Redirect to items list after deletion
    } catch (error) {
      console.error('Error deleting item: ', error);
    }
  };

  const handleDeleteImage = async (url) => {
    try {
      await deleteImage(userId, itemId, url);
      setItem((prevItem) => ({
        ...prevItem,
        photoUrls: prevItem.photoUrls.filter((photoUrl) => photoUrl !== url),
      }));
    } catch (error) {
      console.error('Error deleting image: ', error);
    }
  };

  const handleCopy = (item) => {
    const { id, createdAt, photoUrls, ...copiedItem } = item;
    navigate('/items/new', { state: { ...copiedItem } });
  };

  const handleAddPhotos = async (event) => {
    setLoading(true); // Start loading
    try {
      const files = Array.from(event.target.files);
      const compressedFiles = await Promise.all(
        files.map((file) => compressImage(file, 0.5))
      );

      const userId = sessionStorage.getItem('userId');
      await addImages(userId, itemId, compressedFiles);

      const updatedItem = await getItem(itemId);
      setItem(updatedItem);
    } catch (error) {
      console.error('Error adding photos:', error);
    }
    setLoading(false); // End loading
  };

  // Handle adding photos with progress tracking
  const handleAddPhotosWithProgress = async (event) => {
    setLoading(true); // Start loading indicator

    try {
      const files = Array.from(event.target.files); // Convert FileList to an array
      const compressedFiles = await Promise.all(
        files.map((file) => compressImage(file, 0.5)) // Compress each file
      );

      // Upload images with progress tracking
      await addImagesWithProgress(userId, itemId, compressedFiles, (progress, file) => {
        const id = `upload-progress-${file.name}`;

        // If the toast does not exist, create it
        if (!toast.isActive(id)) {
          toast.info(`Uploading ${file.name}`, {
            toastId: id,
            autoClose: false, // Don't auto-close the toast
            closeButton: false, // Remove close button
          });
        }

        // Update the toast with the current progress
        toast.update(id, {
          render: `Uploading ${file.name}: ${progress.toFixed(2)}%`,
        });

        // When upload completes, update toast to show success
        if (progress === 100) {
          toast.update(id, {
            render: `Uploaded ${file.name} successfully!`,
            type: "success",  // Indicate success with appropriate style
            autoClose: 2000,  // Automatically close after 2 seconds
            closeButton: true, // Add close button
          });
        }
      });

      const updatedItem = await getItem(itemId); // Get updated item from Firestore
      setItem(updatedItem); // Update local state with new item data
    } catch (error) {
      console.error('Error adding photos:', error); // Log any errors
      toast.error('Error uploading photos.'); // Show error toast
    }

    setLoading(false); // End loading indicator
  };

  return {
    handleUpdate,
    handleDelete,
    handleDeleteImage,
    handleCopy,
    handleAddPhotos,
    handleAddPhotosWithProgress,
  };
};