import React, { useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import DrawerContext from '../../contexts/drawer-context';
import { NavLink } from 'react-router-dom';

const StyledDrawer = (theme, drawerWidth) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: '250ms'
    }),
    position: 'relative',
    overflowX: 'hidden',
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

const LeftDrawer = ({ currentPath }) => {
  const theme = useTheme()
  const { drawerState, toggleDrawerIsIcon, drawerWidth } = useContext(DrawerContext);
  
  const drawerLinks = [
    { label: 'Add Item', icon: <AddIcon />, path: '/items/new' },
    { label: 'Table', icon: <TableRowsIcon />, path: '/items/table' },
    { label: 'Grid', icon: <GridViewIcon />, path: '/items/grid' },
  ];

  const canAddItem = currentPath === '/items/table' || currentPath === '/items/grid';
  return (
    <Drawer
      sx={StyledDrawer(theme, drawerWidth)}
      variant="persistent"
      anchor="left"
      open={drawerState !== 'closed'}
    >
      <DrawerHeader theme={theme}>
        <IconButton onClick={toggleDrawerIsIcon}>
          {drawerState !== 'icon' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          paddingLeft: '5px',
          paddingTop: '0px',
        }}
      >
        {drawerLinks.map((link, index) => (
          <Box
            key={index}
            component={NavLink}
            to={link.label === 'Add Item' && !canAddItem ? undefined : link.path}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              height: '48px',
              cursor: link.label === 'Add Item' && !canAddItem ? 'not-allowed' : 'pointer',
              opacity: link.label === 'Add Item' && !canAddItem ? 0.5 : 1,
            }}
            onClick={(e) => {
              if (link.label === 'Add Item' && !canAddItem) {
                e.preventDefault();
                return;
              }
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px' }}>
              {link.icon}
            </ListItemIcon>
            {drawerState === 'full' &&
              <ListItemText
                primary={link.label}
                sx={{
                  lineHeight: '48px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default LeftDrawer;