import React, { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Ensuring the CSS is imported
import Main from './pages/main/main'
import Entry from './pages/entry'
import Settings from './pages/settings/settings'
import Home from './pages/home'
import Items from './pages/items/items'
import Item from './pages/items/item'
import ItemNew from './pages/items/item-new'
import Sandbox from './pages/sandbox'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { red, blue, grey, blueGrey } from '@mui/material/colors'
import { DrawerProvider } from './contexts/drawer-context'
import Nodes from './pages/nodes/nodes'
import ItemsTable from './pages/items/items-table'


const theme = createTheme({
  palette: {
    primary: {
      light: blue[500],
      main: blue[700],
      dark: blue[800],
      contrastText: blueGrey[50],
    },
    secondary: {
      light: red[500],
      main: red[600],
      dark: red[700],
      contrastText: '#fff',
    },
    appbar: {
      light: blueGrey[50],
      main: blueGrey[300],
      dark: blueGrey[500],
      contrastText: grey[50],
    },
  },
});

function App() {
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Fragment>
          <DrawerProvider>
            <Main>

              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/signup" element={<Entry />} />
                <Route exact path="/signin" element={<Entry />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route path="/items" element={<Items />} />
                <Route path="/items/new" element={<ItemNew />} />
                <Route path="/items/table" element={<Items />} />
                <Route path="/items/grid" element={<Items />} />
                <Route path="/items/:itemId" element={<Item />} />
                {/* <Route path="/node/:nodeId" element={<NodeItems />} /> 
                <Route path="/nodes" element={<Nodes />} /> 
                <Route exact path="/sandbox" element={<Sandbox />} /> */}
              </Routes>
            </Main>
            </DrawerProvider>
            <ToastContainer position="top-right" 
            autoClose={5000} 
            hideProgressBar={false} 
            newestOnTop={false} 
            closeOnClick rtl={false} 
            pauseOnFocusLoss 
            draggable 
            pauseOnHover />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}

          </Fragment>
        </Router>

      </ThemeProvider>

    </>

  );
}

export default App;