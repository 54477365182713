import React  from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import './index.css';
import App from './app';
import * as serviceWorkerRegistration from './sw-registration'; // Import serviceWorkerRegistration


import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <CssBaseline />
      <App />
    </QueryClientProvider>
  </React.StrictMode> 

);

serviceWorkerRegistration.register();