// src/components/grid-view.jsx
import { Grid } from '@mui/material';
import ItemCard from './item-card';

const ItemsGrid = ({ items }) => {
  return (
    <Grid 
    container spacing={2} 
    justify="center">
      {items.map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
          <ItemCard
          item={item} 
          minWidth='240px' 
          maxWidth='412px' />
        </Grid>
      ))}
    </Grid>
  );
};

export default ItemsGrid;